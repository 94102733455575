import * as React from "react"
import QuestionLayout from "../../components/question-layout/question-layout"

const QuestionPage4 = () => {
  return (
    <QuestionLayout
      backgroundColor="blue"
      additClass="-step-4"
      contentHTML="
        This one is complicated: You noted that Bitcoin has risen 15% in 3 hours and the RSI indicator shows triple divergence. It's ok if you don't know the answer. I'm sure you're experienced enough to make an educated guess. Also, be sure to deploy proper risk management in your trade.
      "
      buyButtonText="Buy"
      buyButtonLink="/answer/4/wrong"
      sellButtonText="Sell"
      sellButtonLink="/answer/4/correct"
      balanceText="Your Balance:"
      currentStep="4"
      images={{
        map: {
          show: true,
          factor: 0.04
        },
        washingtonTop: {
          show: false,
          factor: 0.08
        },
        dotsLeft: {
          show: true,
          factor: 0.12
        },
        chartDown: {
          show: false,
          factor: 0.16
        },
        dollarSmall: {
          show: false,
          factor: 0.2
        },
        bitcoin: {
          show: false,
          factor: 0.24
        },
        plusesHorizontal: {
          show: false,
          factor: 0.28
        },
        plusesHorizontalGreen: {
          show: true,
          factor: 0.28
        },
        washingtonBottom: {
          show: false,
          factor: 0.32
        },
        dollarBig: {
          show: false,
          factor: 0.36
        },
        plusesVertical: {
          show: false,
          factor: 0.4
        },
        plusesVerticalGreen: {
          show: true,
          factor: 0.4
        },
        dotsRight: {
          show: true,
          factor: 0.4
        },
        chartUp: {
          show: false,
          factor: 0.48
        },
        satoshiTop: {
          show: false,
          factor: 0.08
        },
        satoshiBottom: {
          show: false,
          factor: 0.32
        },
        bankTop: {
          show: false,
          factor: 0.08
        },
        bankBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowGreenRedTop: {
          show: true,
          factor: 0.08
        },
        chartArrowGreenRedBottom: {
          show: true,
          factor: 0.32
        },
        chartArrowGreenTop: {
          show: false,
          factor: 0.08
        },
        chartArrowGreenBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowYellowTop: {
          show: false,
          factor: 0.08
        },
        chartArrowYellowBottom: {
          show: false,
          factor: 0.32
        },
        questionMarkTop: {
          show: true,
          factor: 0.12
        },
        questionMarkBottom: {
          show: true,
          factor: 0.32
        },
        chartGreenUp: {
          show: true,
          factor: 0.48
        },
        chartGreenDown: {
          show: true,
          factor: 0.16
        },
        bitcoinBottom: {
          show: true,
          factor: 0.36
        }
      }}
    ></QuestionLayout>
  )
}

export default QuestionPage4